.App {
    background-color: #f9f9f9d3;
    min-height: 100vh;
}

.search {
    background-image: url('https://cdn-icons-png.flaticon.com/512/54/54481.png');
    background-repeat: no-repeat;
    background-size: 21px 21px;
    background-position: 97% 8px;
    border-radius: 1.25rem!important;
}

.card-thumbnail {
    background: #FFFFFF;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.05);
    border: none!important;
    border-radius: 0.8rem!important;
    padding: 0.45rem;
    position: relative;
}

.image-product {
    border-radius: 0.8rem;
}

.title-sku {
    font-size: 12px;
    font-weight: bold;
    color: #00AC92;
}

.title-name {
    font-size: 12px;
    letter-spacing: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    height: 29px;
    overflow: hidden;
    line-height: 15px;
}

.title-sku-modal {
    font-size: 15px;
    font-weight: bold;
    color: #00AC92;
}

.title-name-modal {
    font-size: 14px;
    letter-spacing: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    height: 35px;
    overflow: hidden;
    line-height: 15px;
    padding-top: 6px;
}

.title-header {
    font-size: 15px;
    font-weight: bold;
}

.title-content {
    font-size: 14px;
    color: #00AC92;
}

.float-search {
    position: fixed;
    background-color: #FFFFFF;
    width: 100%;
    z-index: 1000;
    padding-left: 15px;
    padding-right: 15px;
    left: 0;
    top: 0;
    box-sizing: border-box;
    border-bottom: 1px solid #fbfafa;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.05);
}

.wrap-product {
    padding-top: 100px;
}

.close-modal {
    background-color: #ea0000;
    position: absolute;
    right: 10px;
    top: 10px;
    color: #ffffff;
    padding: 0px 9px;
    border-radius: 50%;
    font-size: 22px;
    text-align: center;
}